/* eslint-disable react/jsx-key */
import React, { Component } from 'react';
import { CoreFeatureSection, Divider, CoreWrapper, GlobalMainWrapperV2 } from './style';
import { ImageUrl, imgPath } from '../../utils/constants';
import PropTypes from "prop-types";
import data from './wellnessResources.json'
import { connect } from 'react-redux';
import { subscribeNewsLetter } from '../../redux/actions/authActions';
import testimonialData from './TestimonialData.json'
import coreData from './Challenges.json'
import coreDataMore from './ChallengesMore.json'
import {ButtonGetStarted,WellBeing} from '../MainDashboardV3/styles';
import {MainResources, RowWrapper, RowResponsiveWrapper} from '../WellnessPageV2/styles'
import { ButtonLearnMoree} from './style';
import { Section, IntroTextContainer, PosterImage,WrapperContainer,ResourcesWrapper,Resources} from '../DashboardRecipes/style';
import LazyImage from '../common/LazyImage/LazyImage';
import { ButtonWatchVideo } from '../CommunityRecognition/style';
class WellnessPageEducation extends Component {

  constructor() {
    super();
    this.state = {
      mobileViewStatus: window.innerWidth <= 500,
      active: '',
      more: 0,
      less: 0,
      selectedImage: '',
      selectedText: 'WHY USERS LOVE US',
      selectedTab: 0,
      wellnessResources: JSON.parse(JSON.stringify(data)),
      image: [data[0].data[0].image],
      email: '',
      tabArray: ["Insurance", "Banking", "Technology", "Healthcare", "Civil Engineering"],
      ratingImage: [testimonialData[0].imageURL, testimonialData[1].imageURL, testimonialData[2].imageURL, testimonialData[3].imageURL, testimonialData[4].imageURL],
      ratingData: [testimonialData[0].data, testimonialData[1].data, testimonialData[2].data, testimonialData[3].data, testimonialData[4].data],
      ratingName: [testimonialData[0].head, testimonialData[1].head, testimonialData[2].head, testimonialData[3].head, testimonialData[4].head],
      ratingMain: [testimonialData[0].main, testimonialData[1].main, testimonialData[2].main, testimonialData[3].main, testimonialData[4].main],
      selectedChallengeData: coreData[0].data,
      selectedChallengeDataMore: coreDataMore[0].data,
    }
  }

  componentDidMount() {
    this.setState({ active: 'Insurance' })
  }
  TopPanelSection = () => (
    <Section firstChild margin="0">
      <IntroTextContainer>
        <h1>
        SUPPORT WELLNESS<br/>LEARNING
        </h1>
        <LazyImage div={"divider"} src={ImageUrl + "/images/RecognitionV2/Rectangle-yellow.png"}/>
        <p>
          Our holistic approach to workplace wellness {this.state.mobileViewStatus ? '' : <br/>}
          leads to happier and healthier employees and {this.state.mobileViewStatus ? '' : <br/>}
          a company that thrives. {this.state.mobileViewStatus ? '' : <br/>}
        </p>
        <div className="wrap">
          <a href='https://woliba.navattic.com/at4p060h' target='blank' style={{textDecoration:"none"}}><ButtonWatchVideo style={{display:'flex',alignItems:"center",justifyContent:"center",color:"#f6b479",border:"2px solid #f6b479"}}>
            <svg width="21" height="24" viewBox="0 0 136 153" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M129.381 65.0255C138.206 70.1255 138.206 82.8753 129.381 87.9752L20.1667 151.086C11.3413 156.186 0.30957 149.812 0.30957 139.612L0.309577 13.3884C0.309577 3.18855 11.3413 -3.18634 20.1667 1.91359L129.381 65.0255Z" fill="#f6b479"/>
            </svg>
            <div style={{marginLeft:'8%'}}>Take a Tour</div></ButtonWatchVideo></a>
          <ButtonLearnMoree onClick={()=> this.props.history.push('/contact')} noMargin={1} style={{marginLeft:"5%"}}>BOOK A DEMO</ButtonLearnMoree>
        </div>
      </IntroTextContainer>
      <PosterImage>
        <picture>
          <source srcSet={ImageUrl + "/images/WellnessEducation/wellnessEducationBanner.webp"} type="image/png"/>
          <LazyImage src={ImageUrl + "/images/WellnessEducation/wellnessEducationBanner.webp"} title="Poster Image"/>
        </picture>
      </PosterImage>
      <LazyImage src={ImageUrl + "/images/WellnessEducation/Wellness_Education_MobImg.webp"} title="Poster Image"/>
    </Section>
  );

  decreaseIndex = () => {
    const { selectedTab } = this.state;
    let temp = selectedTab - 1;
    this.setState({ selectedTab: temp })
  }

  increaseIndex = () => {
    const { selectedTab } = this.state;
    let temp = selectedTab + 1;
    this.setState({ selectedTab: temp })
  }

  handleTabs = (index) => {
    this.setState({ selectedTab: index });
  }

  handleResources = (resIndex, rowIndex) => {
    const { wellnessResources, image } = this.state;
    let array = wellnessResources;
    wellnessResources[resIndex].data.forEach((info, index) => {
      array[resIndex].data[index].status = info.status === 1 && 0;
    })
    array[resIndex].data[rowIndex].status = 1;
    let images = image;
    images[resIndex] = wellnessResources[resIndex].data[rowIndex].image;
    this.setState({ wellnessResources: array, image: images });
  }

  multipleFeatures = () => (
    <ResourcesWrapper>{
      this.state.wellnessResources.length > 0 && this.state.wellnessResources.map((row, index) =>
        (<MainResources key={index} color={row.color}>
          <Resources flexDirection={row.swapRow} col={row.color}>
            <div>
              <div />
              <div className="normal">
                {
                  row.data.map((info, rowindex) => (
                    <RowWrapper key={rowindex} selected={info.status} bgColor={row.bgColor}
                      onClick={() => this.handleResources(index, rowindex)}
                      customColor={info.status === 1 ? 1 : 0}>
                      <div className={"header"}>
                        <span><LazyImage src={ImageUrl +"/images/" + `${info.icon}`} alt={row.title}/></span>
                        <div><h3>{info.header}</h3></div>
                        <LazyImage div={"downArrow"} src={ImageUrl +"/images/WellnessPage/Vector (1).svg"} alt={"arrow"}/>
                      </div>
                      {(info.status === 1) && <p className={"expand"}>
                        {info.description}
                      </p>}
                    </RowWrapper>
                  ))
                }
              </div>
              <div className="responsive">
                {
                  row.data.map((info, rowindex) => (
                    <div>
                      <RowResponsiveWrapper key={rowindex} selected={info.status} bgColor={row.bgColor} onClick={() => this.handleResources(index, rowindex)} customColor={info.status === 1 ? 1 : 0}>
                        <div className={"header"}>
                          <span><LazyImage src={ImageUrl +"/images/" + `${info.icon}`} alt={row.title}/></span>
                          <div>{info.header}</div>
                          <LazyImage div={"downArrow"} src={ImageUrl +"/images/WellnessPage/Vector (1).svg"} alt={"arrow"}/>
                        </div>
                        {(info.status === 1) && <p className={"expand"}>
                          {info.description}
                        </p>}
                      </RowResponsiveWrapper>
                      {(info.status === 1) && <LazyImage div={"responsive-image"} src={ImageUrl + "/images/" + this.state.image[index]}/>}
                    </div>
                  ))
                }
              </div>
            </div>
            <LazyImage div={"image"} src={ImageUrl + "/images/" + this.state.image[index]}/>

          </Resources>
        </MainResources>))
    }</ResourcesWrapper>);
  coreFeatureSection = () => (
    <CoreFeatureSection>
      <div>
        <h2>Improve Awareness For Personal Health</h2>
        <LazyImage src={ImageUrl + "/images/HomePageV2/line.svg"}/>
      </div>
    </CoreFeatureSection>
  );

  wellBeingSection = () => (
    <WellBeing>
      < div>
        <div>
          <p>Are you ready to <br/>
          create a culture of <br/>
          wellbeing?</p>
          <ButtonGetStarted  display={'block'} onClick={()=> this.props.history.push('/contact')}>GET STARTED</ButtonGetStarted>
        </div>
      </div>
      <LazyImage src={ImageUrl + "/images/HomePageV2/Schedule_a_demo.webp"} />
    </WellBeing>
  )

  onEmailChange = (e) => {
    this.setState({ email: e.target.value })
  }

  divider = () => (
    <Divider>
      <img src={`${imgPath}/WellnessPage/blueDivider.png`} alt={"breaker"} />
    </Divider>
  )
  onSubmit = (e) => {
    e.preventDefault();
    const { subscribeNewsLetter, history } = this.props;
    const { email } = this.state;
    let payload = {
      email
    };
    if (email.trim() !== '') {
      subscribeNewsLetter(history, payload);
      this.setState({
        email: ''
      })
    }
  };

  globalPositionSection = () => (
    <GlobalMainWrapperV2>
      <div>
        <h2>We are consistently ranked as a leader</h2>
        <LazyImage src={ImageUrl + "/images/HomePageV2/line.svg"}/>
      </div>
      <div>
        <LazyImage src={ImageUrl + "/images/NewHomePageV2/container1.png"} width="70%"/>
        <LazyImage src={ImageUrl + "/images/NewHomePageV2/container2.png"} width="70%"/>
        <LazyImage src={ImageUrl + "/images/NewHomePageV2/container3.png"} width="70%"/>
        <LazyImage src={ImageUrl + "/images/NewHomePageV2/container4.png"} width="70%"/>
        <LazyImage src={ImageUrl + "/images/NewHomePageV2/container5.png"} width="70%"/>
      </div>
    </GlobalMainWrapperV2>
  )

  readMore = () => {
    this.setState({more: !this.state.more});
    this.setState({less: !this.state.less});
  }

  renderCards = () => (
    <CoreWrapper>
      <div>
        <div className="tab-header">
          <h2>Comprehensive Wellness Solutions</h2>
          <LazyImage src={ImageUrl + "/images/HomePageV2/line.svg"}/>
        </div>
      </div>
      <div className="card-container">
        {this.state.selectedChallengeData.map((row, index) => (
          <div className="card-data" key={index}>
            <LazyImage div={"image-container"} src={ImageUrl + row.img}/>
            <div className="card-title">
              <h3>{row.head}</h3>
            </div>
            <div className="card-details">
              <p>{row.desc}</p>
            </div>
          </div>
        ))}
      </div>
      {this.state.more?
        <div className="card-container">
          {this.state.selectedChallengeDataMore.map((row, index) => (
            <div className="card-data" key={index}>
              <LazyImage div={"image-container"} src={ImageUrl + row.img}/>
              <div className="card-title">
                <h3>{row.head}</h3>
              </div>
              <div className="card-details">
                <p>{row.desc}</p>
              </div>
            </div>
          ))}
        </div>:<span />
      }
      <div className="card-button">
        <button onClick={() => this.readMore()}>
          {this.state.less?
            ("View Less") : ("View More")
          }
        </button>
      </div>
    </CoreWrapper>
  )

  render() {
    return (
      <WrapperContainer>
        {this.TopPanelSection()}
        {this. coreFeatureSection()}
        {this.multipleFeatures()}
        {this.globalPositionSection()};
        {this.renderCards()}
        {this.wellBeingSection()}
      </WrapperContainer>
    );
  }
}
WellnessPageEducation.propTypes = {
  history: PropTypes.object,
  subscribeNewsLetter: PropTypes.func
};
const mapDispatchToProps = (dispatch) => ({
  subscribeNewsLetter: (history, payload) => dispatch(subscribeNewsLetter(history, payload))
});
export default connect(null, mapDispatchToProps)(WellnessPageEducation);
